<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add amount invested</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="addAmountInvested()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <p>Please specify amount to be added on <b>{{ bond.issue_number }}</b></p>
                  <v-text-field
                    v-model="add_amount_invested"
                    label="Add amount invested"
                    :rules="numberRules"
                    placeholder="e.g 10000"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'
import interestPayingDatesArrayToString from '@/mixins/interestPayingDatesArrayToString'

export default {
  mixins: [validationRules, interestPayingDatesArrayToString],
  props: {
    showDialog: {
      type: Boolean,
    },
    bond: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    add_amount_invested: '',
    form: new Form({
      issue_number: '',
    }),
    isRolesLoading: false,
    action: 'Add',
  }),
  watch: {
    bond() {
      if (Object.keys(this.bond).length > 0) {
        this.form.update(this.bond)
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
      this.$refs.form.reset()
    },
    addAmountInvested() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form.amount_invested = parseInt(this.form.amount_invested, 0) + parseInt(this.add_amount_invested, 0)
        this.form.interest_payment_dates = this.interestPayingDatesArrayToString(
          this.form.interest_paying_dates,
        )
        this.form
          .put(`bonds/${this.form.id}`)
          .then(() => {
            this.$toast.success('Amount added successfully')
            this.$emit('bond-updated')
            this.$refs.form.reset()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
