<template>
  <div class="connectionFee">
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          class="mb-5"
          @click="showCreateOrUpdateBondDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiShapeSquarePlus }}
          </v-icon>
          Add Bond
        </v-btn>
      </v-col>
    </v-row>
    <bonds-table
      :show-create-or-update-bond-dialog="showCreateOrUpdateBondDialog"
      @show-create-or-update-bond-dialog="showCreateOrUpdateBondDialog = true"
      @close-dialog="showCreateOrUpdateBondDialog = false"
      @bond-created="showCreateOrUpdateBondDialog = false"
      @bond-updated="showCreateOrUpdateBondDialog = false"
    />
  </div>
</template>
<script>
import {
  mdiShapeSquarePlus,
} from '@mdi/js'
import BondsTable from '@/components/tables/BondsTable.vue'

export default {
  components: {
    BondsTable,
  },
  data() {
    return {
      showCreateOrUpdateBondDialog: false,
      icons: {
        mdiShapeSquarePlus,
      },
    }
  },
}
</script>
