export default {
  methods: {
    interestPayingDatesArrayToString(interestPayingDates) {
      let datesString = ''

      for (let i = 0; i < interestPayingDates.length; i++) {
        datesString += interestPayingDates[i].interest_paying_date

        if (i < interestPayingDates.length - 1) {
          datesString += ', '
        }
      }

      return datesString
    },
  },
}
