<template>
  <div class="bonds">
    <v-card>
      <v-card-title>
        Bonds
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="bonds"
        :loading="isBondsLoading"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          <span>{{ item.name }}</span>
        </template>
        <template v-slot:item.coupon_rate="{ item }">
          <span>{{ item.coupon_rate | formatCouponRate }}</span>
        </template>
        <template v-slot:item.amount_invested="{ item }">
          <span>{{ item.amount_invested | formatCurrency }}</span>
        </template>
        <template v-slot:item.maturity_date="{ item }">
          <span>{{ item.maturity_date | formatDate }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click="addAmountInvested(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiWalletPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Add amount invested</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click="editBond(item)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click="showDeleteBondDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
      <create-or-update-bonds-dialog
        :show-dialog="showCreateOrUpdateBondDialog"
        :bond="bond"
        @close-dialog="closeCreateOrUpdateBondDialog"
        @bond-created="bondCreated"
        @bond-updated="bondUpdated"
      />
      <confirm-dialog
        :show-dialog="showConfirmDeleteDialog"
        :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
        :agree-text="'Delete'"
        @cancel="showConfirmDeleteDialog = false"
        @agree="deleteBond()"
      />
      <add-amount-invested-dialog
        :show-dialog="showAddAmountInvestedDialog"
        :bond="bond"
        @close-dialog="closeAddAmountInvestedDialog"
        @bond-updated="bondUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiPencil, mdiDelete, mdiWalletPlus,
} from '@mdi/js'
import CreateOrUpdateBondsDialog from '../dialogs/CreateOrUpdateBondsDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import AddAmountInvestedDialog from '@/components/dialogs/AddAmountInvestedDialog.vue'

export default {
  components: {
    CreateOrUpdateBondsDialog,
    ConfirmDialog,
    AddAmountInvestedDialog,
  },
  props: {
    showCreateOrUpdateBondDialog: {
      type: Boolean,
    },
  },
  data: () => ({
    showConfirmDeleteDialog: false,
    showAddAmountInvestedDialog: false,
    isConfirmDeleteDialogButtonLoading: false,
    toDelete: null,
    sortBy: ['created_at'],
    sortDesc: [true],
    pagination: {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    },
    search: '',
    headers: [
      {
        text: 'Issue number',
        value: 'issue_number',
        sortable: false,
      },
      {
        text: 'Coupon rate',
        value: 'coupon_rate',
      },
      {
        text: 'Amount invested',
        value: 'amount_invested',
      },
      {
        text: 'Maturity Date',
        value: 'maturity_date',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    icons: {
      mdiPencil,
      mdiDelete,
      mdiWalletPlus,
    },
    bond: {},
    bonds: [],
    isBondsLoading: true,
    filters: {
      year: '',
      month: '',
      paymentStatus: [],
    },
  }),
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
  },
  mounted() {
    this.getBonds()
  },
  methods: {
    getBonds: _.debounce(function () {
      this.isBondsLoading = true

      axios.get(`bonds?page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`)
        .then(({ data: { data } }) => {
          this.bonds = data
        })
        .catch(({ response: { data: { message } } }) => {
          this.$toast.error(message)
        })
        .finally(() => {
          this.isBondsLoading = false
        })
    }, 500),
    deleteBond() {
      this.isConfirmDeleteDialogButtonLoading = true

      axios.delete(`bonds/${this.toDelete}`)
        .then(() => {
          this.getBonds()
          this.closeCreateOrUpdateBondDialog()
          this.$toast.success('Bond deleted successfully')
        })
        .catch(({ response: { data: { message } } }) => {
          this.$toast.error(message)
        })
        .finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    editBond(bond) {
      this.bond = bond
      this.$emit('show-create-or-update-bond-dialog', true)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getBonds()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getBonds()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    applyFilters(filters) {
      this.filters = filters
      this.getBonds()
    },
    closeCreateOrUpdateBondDialog() {
      this.bond = {}
      this.$emit('close-dialog')
    },
    closeAddAmountInvestedDialog() {
      this.bond = {}
      this.showAddAmountInvestedDialog = false
    },
    bondCreated() {
      this.getBonds()
      this.$emit('bond-created')
      this.closeCreateOrUpdateBondDialog()
    },
    bondUpdated() {
      this.getBonds()
      this.$emit('bond-updated')
      this.closeCreateOrUpdateBondDialog()
      this.closeAddAmountInvestedDialog()
    },
    showDeleteBondDialog(id) {
      this.toDelete = id
      this.showConfirmDeleteDialog = true
    },
    addAmountInvested(bond) {
      this.bond = bond
      this.showAddAmountInvestedDialog = true
    },
  },
}
</script>
