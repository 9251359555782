<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Bond</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="form.issue_number"
                    label="Issue number"
                    :rules="requiredRules"
                    placeholder="e.g IFB1/2021/21"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('issue_number')"
                    class="validation-error"
                  >{{
                    form.errors.get('issue_number')
                  }}</small>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.coupon_rate"
                    label="Coupon rate"
                    :rules="numberRules"
                    placeholder="e.g 10"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('coupon_rate')"
                    class="validation-error"
                  >{{ form.errors.get('coupon_rate') }}</small>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.amount_invested"
                    label="Amount invested"
                    :rules="numberRules"
                    placeholder="e.g 1000000"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('amount_invested')"
                    class="validation-error"
                  >{{ form.errors.get('amount_invested') }}</small>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="form.interest_payment_dates"
                    label="Interest payment dates"
                    :rules="requiredRules"
                    rows="4"
                    row-height="4"
                    placeholder="Enter interest payment dates separated by space or comma e.g 01/01/2021, 01/02/2021, 01/03/2021 01/04/2021"
                    required
                  ></v-textarea>
                  <small
                    v-show="form.errors.has('interest_payment_dates')"
                    class="validation-error"
                  >{{ form.errors.get('interest_payment_dates') }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'
import interestPayingDatesArrayToString from '@/mixins/interestPayingDatesArrayToString'

export default {
  mixins: [validationRules, interestPayingDatesArrayToString],
  props: {
    showDialog: {
      type: Boolean,
    },
    bond: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      issue_number: '',
      coupon_rate: '',
      amount_invested: '',
      interest_payment_dates: '',
    }),
    isRolesLoading: false,
    action: 'Save',
  }),
  watch: {
    bond() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      if (Object.keys(this.bond).length > 0) {
        this.form.update(this.bond)
        this.form.interest_payment_dates = this.interestPayingDatesArrayToString(
          this.form.interest_paying_dates,
        )
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveBond()
        } else {
          this.updateBond()
        }
      }
    },
    saveBond() {
      this.form
        .post('bonds')
        .then(() => {
          this.$toast.success('Bond added successfully')
          this.$emit('bond-created')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateBond() {
      this.form
        .put(`bonds/${this.form.id}`)
        .then(() => {
          this.$toast.success('Bond updated successfully')
          this.$emit('bond-updated')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
